import * as React from 'react';
import PropTypes from 'prop-types';
import constants from '../../../globals/constants/application-constants';
import envConstants from '../../../globals/constants/environment-constants';
import { getBrowserStorage, initInvoca, runInvoca } from '../../../common';
import useWindowResize from '../../../hooks/useWindowResize';
import TopBanner from '../../top-banner/TopBanner';
import useSharedTabState from '../../../hooks/tabState';
import { AppContext } from '../../../globals/context/AppContext';
import getPartnerAllianceInfo from '../../../partner-alliance-info';

interface ILayoutProps {
  children: React.ReactNode;
}

const isBrowser = typeof window !== 'undefined';
let allid = '';
if (isBrowser) {
  allid = getBrowserStorage('allid');
}

const MainLayout = ({ children }: ILayoutProps): JSX.Element => {
  const [hasBanner, setHasBanner] = React.useState(false);
  const windowInnerwidth = useWindowResize(true);
  const [mainOffset, setMainOffset] = React.useState(windowInnerwidth < 1200 ? 77 : 120);
  const { active } = useSharedTabState();
  const { setPhone, setPartnerAllianceInfo } = React.useContext(AppContext);

  const handleSetMainMarginTop = () => {
    const headerHeight = windowInnerwidth < 1200 ? 77 : 120;
    if (hasBanner) {
      const bannerHeight = document
        .getElementsByClassName('alert')[0]
        ?.getBoundingClientRect().height;
      setMainOffset(headerHeight + bannerHeight);
    } else {
      setMainOffset(headerHeight);
    }
  };

  const loadInvocaDone = (tryCount: number) => {
    const { Invoca } = window as any;
    if (
      Invoca &&
      Invoca.PNAPI &&
      Invoca.PNAPI.currentPageSettings &&
      Invoca.PNAPI.currentPageSettings.networkId
    ) {
      Invoca.PNAPI.currentPageSettings.onComplete = function () {
        const invocaPhone = arguments[0][0].formattedNumber;
        if (invocaPhone) {
          setPhone({ phoneNumber: `1-${invocaPhone}`, isFinal: true });
        }
      };
    } else if (tryCount < 10) {
      setTimeout(() => {
        loadInvocaDone(tryCount + 1);
      }, 500);
    } else {
      setPhone({ phoneNumber: constants.DEFAULT_PHONE, isFinal: true });
    }
  };

  const getPartnerAllianceInfoDone = async () => {
    setPartnerAllianceInfo(await getPartnerAllianceInfo());
  };

  React.useEffect(() => {
    getPartnerAllianceInfoDone();
    initInvoca();
    loadInvocaDone(0);
    setHasBanner(envConstants.SUBDOMAIN?.length > 0);
    handleSetMainMarginTop();
  }, []);

  React.useLayoutEffect(() => {
    handleSetMainMarginTop();
  }, [windowInnerwidth]);

  React.useEffect(() => {
    runInvoca(active);
  }, [active]);

  return (
    <div>
      <div className="header-wrapper">
        {hasBanner ? <TopBanner /> : ''}
        <ehi-header
          allianceId={allid}
          page="home"
          menuOverlayInheritMarginTop={hasBanner ? 'Y' : 'N'}
          activetab={`tab-${active}`}
          version="v2"
        >
          <div slot="invoca-header--mobile" className="invoca-phone-number phone-number">
            {constants.DEFAULT_PHONE}
          </div>
          <div slot="invoca-header--tablet" className="invoca-phone-number phone-number">
            {constants.DEFAULT_PHONE}
          </div>
          <div slot="invoca-header--desktop" className="invoca-phone-number phone-number">
            {constants.DEFAULT_PHONE}
          </div>
        </ehi-header>
      </div>
      <main style={{ marginTop: `${mainOffset}px` }} className="mt-[77px] xl:mt-[120px]">
        {children}
      </main>
      <ehi-footer allianceId={allid} page="home" showMedDisclaimer={active === 0} version="v2">
        <div id="norton__footer" slot="norton__footer">
          <div id="DigiCertClickID_onwANETs_1" />
        </div>
      </ehi-footer>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
